import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const ArtCenter = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { molaCloudinary } = useStaticQuery(graphql`
    query {
      molaCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/mola/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>07. Maria of Los Angeles</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Book Design, Typeface Design, Web </h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-mola">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/pueblo-sonidero-mediatecture">
                        Previous Project(06) ↖
                      </Link>
                    </h2>
                    <h2>
                      <Link to="/projects/rifla">Next Project(08) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Beginnings</p>
            </div>
            <div className="col-lg-7">
              <p>
                MOLA began in 2015 when I started to collect the many diverse
                representations of the Virgen de Guadalupe in the city of Los
                Angeles.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[0].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>First Publication</p>
            </div>
            <div className="col-lg-7">
              <p>
                As the photographs increased in numbers I began to catalog the
                murals with the intentions of creating an image based
                publication under the mentorship of River Jukes-Hudson in 2018.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Book</p>
            </div>
            <div className="col-lg-7">
              <p>
                They have now become much more than images of these beautiful
                murals. They are an archive, a preservation of history, and a
                form of storytelling. I would love to tell the story not only of
                the murals and the artists, but also of those who have them in
                their businesses or homes.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Maria of Los Angeles</p>
            </div>
            <div className="col-lg-7">
              <p>
                This project is designed to serve the community of Los Angeles
                specifically the immigrant community, as the Virgen de Guadalupe
                has traveled with them or met them here in Los Angeles.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[23].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[24].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[25].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[26].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[27].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[28].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[29].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[30].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[31].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[32].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[33].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={molaCloudinary.edges[34].node.secure_url}
                alt=""
              />
            </div>
          </div>

          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Stephen Serrato (Professor)</p>
              <p>River Jukes-Hudson (Professor)</p>
              <p>Miller Sherwood printing</p>
              <p>El Sereno Graphics</p>
              <p>Chromatic Inc.</p>
              <p>Roberto Lint Sagarena (Writer)</p>
              <p>Natalia Portillo</p>
              <p>Deysi Diaz</p>
              <p>Devin Diaz</p>
              <p>Maria de los Angeles</p>
              <p>Eliud Perez</p>
              <p>
                <a
                  href="https://www.instagram.com/el_kiko_81_/"
                  rel="noreferrer"
                  target="_blank"
                >
                  el_kiko_81_ (Trokero Locochon)
                </a>
              </p>
              <p>The community of los Angeles</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default ArtCenter
